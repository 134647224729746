<template>
  <div class="mt-1 mx-3">
    <priv-easy />
  </div>
</template>

<script>
import PrivEasy from "@/components/priv-easy/index.vue";
// import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";
import { mapActions } from "vuex";

export default {
  // mixins: [ShortcutsAndBreadcrumbsMixin],
  // data() {
  //   return {
  //     path: "module.PEASY.PRIV",
  //     tabs: [],
  //     tabsGroup: "",
  //     bcs: [
  //       {
  //         text: "Priv-Easy",
  //         path: "",
  //         level: 0,
  //         disabled: true,
  //       },
  //       {
  //         text: "Clienti",
  //         path: "module.PEASY.PRIV",
  //         level: 1,
  //       },
  //     ],
  //   };
  // },
  props: {
    msg: String,
  },
  components: {
    PrivEasy,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Priv-Easy",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Clienti",
      path: "module.PEASY.PRIV",
      level: 2,
    });
  },
};
</script>
